import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Observable, from } from 'rxjs';
import { Obituary } from '@structure/Obituary.interface';

@Injectable()
export class ObituaryDataService {
  constructor(private functions: Functions) {}

  getObituary(caseId: string): Observable<Obituary | null> {
    return from(
      httpsCallable(
        this.functions,
        'fetchObit'
      )({ caseId })
        .then((result) => {
          return result.data as Obituary;
        })
        .catch((e) => {
          console.error(e);
          return null;
        })
    );
  }

  approveObituary(
    caseId: string,
    partialObituary: Partial<Obituary>
  ): Observable<any> {
    return from(
      httpsCallable(
        this.functions,
        'approveObit'
      )({ caseId, partialObituary })
        .then((result) => {
          return result.data;
        })
        .catch((e) => {
          console.error(e);
          return '';
        })
    );
  }

  getObitUploadUrls(
    caseId: string,
    filemeta: { filename: string; contentType: string }[]
  ): Observable<{ [filename: string]: string }> {
    return from(
      httpsCallable(
        this.functions,
        'obitUploadUrls'
      )({ caseId, filemeta })
        .then((result) => {
          return result.data as { [filename: string]: string };
        })
        .catch((e) => {
          console.error(e);
          return {};
        })
    );
  }
}
